function ninjaFormsThankYouModal() {
	const $modalThankYou = $( '#modal-thank-you' );
	if ( !$modalThankYou.length ) { return; }
	const $message = $modalThankYou.find( '.modal-message__description' );

	const closeAllModals = () => {
		$( '.modal' ).modal( 'hide' ); // Close all currently open modals
	};
	const openModal = ( hash, message ) => {
		closeAllModals();
		$message.html( message );
		$modalThankYou.modal( 'show' );
		history.replaceState( null, document.title, location.pathname + location.search + hash );
	};

	$modalThankYou.on( 'hidden.bs.modal', () => {
		history.replaceState( null, document.title, location.pathname + location.search );
		$message.html( '<p>' + $message.attr( 'data-message-default' ) + '</p>' );
	} );

	$( document ).on( 'nfFormSubmitResponse', function( event, response ) {
		const formId = response.id;
		const $formCont = $( '#nf-form-' + formId + '-cont' );
		const responseData = response.response.data;

		/* eslint-disable camelcase */
		const { redirect, success_message } = responseData.actions;
		/* eslint-enable camelcase */

		let hash = 'thank-you';
		if ( !redirect ) {
			if ( response.response.errors.length === 0 ) {
				const classList = Array.from( $formCont[0].classList );
				const matchingClass = classList.find( ( item ) => item.startsWith( 'js-form_' ) );
				if ( matchingClass ) {
					hash += `-${matchingClass.split( '_' )[1]}`;
				}
				openModal( '#' + hash, success_message );
				$formCont.find( '.nf-response-msg' ).html( '' );
			}
		}
	} );
}

export default ninjaFormsThankYouModal;
